<template>
  <div>
    <!-- 签约管理 -->
    <ch-layout>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  size="mini" height="100%">

          <template v-slot:city="scope">
            <span>{{
                scope.row.province +
                scope.row.city +
                scope.row.county || "—"
              }}</span>
          </template>
          <template v-slot:handle="scope">
            <ch-button type="link" v-if="false"
                       @click="handleRenewal(scope.row)"
            >延期</ch-button>
            <router-link to="/detailsOrder">
              <ch-button type="link" >服务明细</ch-button>
            </router-link>
            <router-link to="/baseOrder">
              <ch-button type="link" >服务订单</ch-button>
            </router-link>
          </template>
        </ch-table>
      </template>
    </ch-layout>
    <!--  延期  -->
    <renewal-modal
        ref="renewal"
        :getListFunction="getCompany"></renewal-modal>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import RenewalModal from "./renewalModal";
import dayjs from "dayjs";

export default {
  components:{RenewalModal},
  data() {
    return {
      isTableLoading: false,
      treeSelect: {
        applyOrder: false, // 应用订单
        baseOrder: false, // 签约订单
        detailsOrder: false, // 签约明细
        renewal: false, // 延期
      },
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '商户编号', prop: 'number', showOverflowTooltip: true },
        {column: 'text', label: '公司名称', prop: 'name', showOverflowTooltip: true},
        {column: 'text', label: '公司简称', prop: 'abbreviation', showOverflowTooltip: true},
        {column: 'slot', label: '行政区域', slotName: 'city', showOverflowTooltip: true },
        {column: 'text', label: '注册日期', prop: 'createTime', showOverflowTooltip: true},
        {column: 'text', label: '到期日期', prop: 'serverEndTime', showOverflowTooltip: true},
        {column: 'text', label: '商户状态', prop: 'status'},
        {column: 'slot', label: '操作', slotName: 'handle', width: '230px', fixed: 'right' }
      ]
    },
    tableList: function () {
      if(this.company) {
        return [{
          ...this.company,
          status: '已签约',
          createTime: this.company.createTime && dayjs(this.company.createTime).format('YYYY-MM-DD HH:mm:ss') // .endOf('day')
        }]
      } else {
        return []
      }
    },
    ...mapGetters(['company'])
  },
  created() {
    this.getCompany(true)
  },
  methods:{
    ...mapActions(['getCompany']),
    // 打开续费
    handleRenewal(row) {
        if(this.$refs.renewal) {
        this.$refs.renewal.open(row)
      }
    },
  }

}
</script>

<style lang="scss">

</style>
